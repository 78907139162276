import React, { useState } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import PageTitle from "../components/PageTitle/PageTitle"
import { BackgroundContainer, StyledContainer } from "../components/Styled/Container"
import Filters from "../components/Filters/Filters"
import Results from "../components/Results/Results"
import USP from "../components/FeaturedContent/FakeFeaturedUSP"
import { FakeProductCarouselsSingleCarsousel } from "../components/ProductCarousels/FakeProductCarousels"

const FakeProduct = {
  images: [
    {
      src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3.jpg?v=1588835297`,
    },
    {
      src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3_699670da-21c1-46c5-b21b-775ffe664e5e.jpg?v=1588835328`,
    },
    {
      src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3_46dc7766-9e52-4cab-9ccb-b87730996ee4.jpg?v=1588835363`,
    },
    {
      src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/Rectangle_198.png?v=1588835386`,
    },
  ],
  title: `Macaw`,
  subTitle: `Cornflower Blue Leather Heel`,
  variants: [
    {
      available: true,
      id: `1`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 28900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      quantityAvailable: 5,
      selectedOptions: [{ name: "size", value: "34" }],
    },
    {
      available: true,
      id: `2`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      quantityAvailable: 2,
      selectedOptions: [{ name: "size", value: "35" }],
    },
    {
      available: true,
      id: `3`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "36" }],
    },
    {
      available: true,
      id: `4`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      quantityAvailable: 5,
      selectedOptions: [{ name: "size", value: "37" }],
    },
    {
      available: true,
      id: `5`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      quantityAvailable: 5,
      selectedOptions: [{ name: "size", value: "38" }],
    },
    {
      available: true,
      id: `6`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "39" }],
    },
    {
      available: true,
      id: `7`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "40" }],
    },
    {
      available: false,
      id: `8`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "41" }],
    },
    {
      available: true,
      id: `9`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "42" }],
    },
    {
      available: true,
      id: `10`,
      price: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      comparedPrice: [
        {
          amount: 25900,
          currencyCode: `AUD`,
        },
        {
          amount: 16900,
          currencyCode: `USD`,
        },
        {
          amount: 15900,
          currencyCode: `EUR`,
        },
        {
          amount: 13900,
          currencyCode: `GBP`,
        },
        {
          amount: 1810000,
          currencyCode: `JPY`,
        },
      ],
      selectedOptions: [{ name: "size", value: "43" }],
    },
  ],
  link: `/`,
  badge: {
    colour: `dark`,
    title: `Sale`,
  },
}

const Text = tw.p`
  mt-0-8 text-16 md:text-22
`

const Container = styled(StyledContainer)`
  ${tw`pb-5-6 md:pb-8`}
`

const CollectionPage = () => {
  const {
    white,
    beige,
    yellow,
    orange,
    pink,
    red,
    purple,
    blue,
    green,
    brown,
    black,
    silver,
    zebra,
  } = useStaticQuery(
    graphql`
      query {
        white: file(relativePath: { eq: "white.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        beige: file(relativePath: { eq: "beige.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        yellow: file(relativePath: { eq: "yellow.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        orange: file(relativePath: { eq: "orange.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        pink: file(relativePath: { eq: "pink.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        red: file(relativePath: { eq: "red.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        purple: file(relativePath: { eq: "purple.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        blue: file(relativePath: { eq: "blue.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        green: file(relativePath: { eq: "green.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        brown: file(relativePath: { eq: "brown.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        black: file(relativePath: { eq: "black.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        silver: file(relativePath: { eq: "silver.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        zebra: file(relativePath: { eq: "zebra.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    `,
  )

  const FakeData = {
    searchTerm: `Women heels`,
    results: Array(19).fill(FakeProduct),
    filterFacets: [
      {
        label: `Feature`,
        key: `feature`,
        type: `text`,
        filters: [
          { key: `wideFeet`, lebel: `Best for wide feet` },
          { key: `narrowFeet`, label: `Best for narrow feet` },
          { key: `lowHeel`, label: `Low heel` },
          { key: `highHeel`, label: `High heel` },
          { key: `orthoticFriendly`, label: `Orthotic friendly` },
        ],
      },
      {
        label: `Size`,
        key: `size`,
        type: `button`,
        filters: [
          { key: `34`, label: `34` },
          { key: `35`, label: `35` },
          { key: `36`, label: `36` },
          { key: `37`, label: `37` },
          { key: `38`, label: `38` },
          { key: `39`, label: `39` },
          { key: `40`, label: `40` },
          { key: `41`, label: `41` },
          { key: `42`, label: `42` },
          { key: `43`, label: `43` },
        ],
      },
      {
        label: `Colour`,
        key: `colour`,
        type: `icon`,
        filters: [
          { key: `white`, label: `white` },
          { key: `beige`, label: `beige` },
          { key: `yellow`, label: `yellow` },
          { key: `orange`, label: `orange` },
          { key: `pink`, label: `pink` },
          { key: `red`, label: `red` },
          { key: `purple`, label: `purple` },
          { key: `blue`, label: `blue` },
          { key: `green`, label: `green` },
          { key: `brown`, label: `brown` },
          { key: `black`, label: `black` },
          { key: `silver`, label: `silver` },
          { key: `zebra`, label: `zebra` },
        ],
      },
      {
        label: `Price`,
        key: `price`,
        type: `range`,
        filters: [
          { key: `all`, label: `All prices` },
          { key: `0-150`, label: `$0 - $150` },
          { key: `150-250`, label: `$150 - $250` },
          { key: `250-300`, label: `$250 - $350` },
          { key: `350+`, label: `$350+` },
        ],
      },
    ],
    sortOptions: [`Top rated`, `New arrivals`, `Lowest price`, `Highest price`],
    colourImages: [
      { colour: `white`, image: { ...white.childImageSharp.fluid } },
      { colour: `beige`, image: { ...beige.childImageSharp.fluid } },
      { colour: `yellow`, image: { ...yellow.childImageSharp.fluid } },
      { colour: `orange`, image: { ...orange.childImageSharp.fluid } },
      { colour: `pink`, image: { ...pink.childImageSharp.fluid } },
      { colour: `red`, image: { ...red.childImageSharp.fluid } },
      { colour: `purple`, image: { ...purple.childImageSharp.fluid } },
      { colour: `blue`, image: { ...blue.childImageSharp.fluid } },
      { colour: `green`, image: { ...green.childImageSharp.fluid } },
      { colour: `brown`, image: { ...brown.childImageSharp.fluid } },
      { colour: `black`, image: { ...black.childImageSharp.fluid } },
      { colour: `silver`, image: { ...silver.childImageSharp.fluid } },
      { colour: `zebra`, image: { ...zebra.childImageSharp.fluid } },
    ],
  }

  const InitialActiveFitlterItems = {}

  FakeData.filterFacets.forEach(facet => (InitialActiveFitlterItems[facet.key] = []))

  const [activeFilterItems, setActiveFilterItems] = useState(InitialActiveFitlterItems)
  const [activeSortItem, setActiveSortItem] = useState("")
  const handleClearFilter = () => setActiveFilterItems(InitialActiveFitlterItems)

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: `Search ${FakeData.searchTerm}`,
    },
  ]

  return (
    <>
      <PageTitle
        title={`"${FakeData.searchTerm}"`}
        paths={paths}
        searchNoResults={FakeData.results.length === 0}
      >
        {FakeData.results.length > 0 ? (
          <Text>
            {FakeData.results.length}{" "}
            {FakeData.results.length === 1 ? `product found` : `prodcuts found`}
          </Text>
        ) : (
          <Text>But don't give up – check the spelling or try less specific search terms.</Text>
        )}
      </PageTitle>
      <BackgroundContainer background={`light`}>
        <Container>
          {FakeData.results.length > 0 && (
            <>
              <Filters
                filterFacets={FakeData.filterFacets}
                activeFilterItems={activeFilterItems}
                setActiveFilterItems={setActiveFilterItems}
                sortOptions={FakeData.sortOptions}
                activeSortItem={activeSortItem}
                setActiveSortItem={setActiveSortItem}
                colourImages={FakeData.colourImages}
                handleClearFilter={handleClearFilter}
              />
              <Results products={FakeData.results} />
            </>
          )}
        </Container>
      </BackgroundContainer>
      {FakeData.results.length === 0 && <FakeProductCarouselsSingleCarsousel />}
      <USP />
    </>
  )
}

export default CollectionPage
